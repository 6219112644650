import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { auth } from './firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import Chat from './chat.js';
import Auth from './Auth.js';
import TalentCardView from './TalentCardView.js';
import './App.css';

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={
            user ? <Chat user={user} /> : <Navigate to="/login" replace />
          } />
          <Route path="/login" element={
            user ? <Navigate to="/" replace /> : <Auth setUser={setUser} />
          } />
          <Route path="/talent-card/:id" element={<TalentCardView />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;