import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebaseConfig';
import './TalentCardView.css';

function TalentCardView() {
  const [talentCard, setTalentCard] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    async function fetchTalentCard() {
      try {
        const talentCardDoc = await getDoc(doc(db, 'talentCards', id));
        if (talentCardDoc.exists()) {
          const data = talentCardDoc.data();
          // Remove sensitive fields before setting the state
          const { userId, sessionId, ...publicData } = data;
          setTalentCard(publicData);
        } else {
          setError('Talent card not found');
        }
      } catch (err) {
        setError('Error fetching talent card');
        console.error(err);
      } finally {
        setLoading(false);
      }
    }

    fetchTalentCard();
  }, [id]);

  if (loading) return <div className="talent-card-view loading">Loading...</div>;
  if (error) return <div className="talent-card-view error">{error}</div>;
  if (!talentCard) return <div className="talent-card-view not-found">No talent card found</div>;

  // Function to format timestamps
  const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A';
    const date = timestamp.toDate();
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short'
    });
  };

  return (
    <div className="talent-card-view">
      <Link to="/" className="back-link">← Back to Home</Link>
      <h1>{talentCard.name !== "Your Name" ? talentCard.name : "Talent Card"}</h1>
      <h2>{talentCard.title}</h2>
      
      <section className="summary">
        <h3>Professional Summary</h3>
        <p>{talentCard.summary}</p>
      </section>
      
      {talentCard.strengths && talentCard.strengths.length > 0 && (
        <section className="strengths">
          <h3>Strengths</h3>
          <ul>
            {talentCard.strengths.map((strength, index) => (
              <li key={index}>{strength}</li>
            ))}
          </ul>
        </section>
      )}
      
      {talentCard.values && talentCard.values.length > 0 && (
        <section className="values">
          <h3>Values</h3>
          <ul>
            {talentCard.values.map((value, index) => (
              <li key={index}>{value}</li>
            ))}
          </ul>
        </section>
      )}
      
      {talentCard.idealWorkEnvironment && (
        <section className="ideal-work-environment">
          <h3>Ideal Work Environment</h3>
          <p>{talentCard.idealWorkEnvironment}</p>
        </section>
      )}
      
      {talentCard.skills && talentCard.skills.length > 0 && (
        <section className="skills">
          <h3>Skills</h3>
          <ul className="skills-list">
            {talentCard.skills.map((skill, index) => (
              <li key={index}>{skill}</li>
            ))}
          </ul>
        </section>
      )}
      
      <section className="preferences-info">
        {talentCard.availability && (
          <div className="availability">
            <h3>Availability</h3>
            <p>{talentCard.availability}</p>
          </div>
        )}
        
        {talentCard.locationPreferences && (
          <div className="location-preferences">
            <h3>Location Preferences</h3>
            <p>{talentCard.locationPreferences}</p>
          </div>
        )}
        
        {talentCard.financialExpectations && (
          <div className="financial-expectations">
            <h3>Financial Expectations</h3>
            <p>{talentCard.financialExpectations}</p>
          </div>
        )}
      </section>
      
      {talentCard.experience && talentCard.experience.length > 0 && (
        <section className="experience">
          <h3>Work Experience</h3>
          {talentCard.experience.map((exp, index) => (
            <div key={index} className="experience-item">
              <h4>{exp.role} at {exp.company}</h4>
              <p className="duration">{exp.duration}</p>
              {exp.highlights && exp.highlights.length > 0 && (
                <ul>
                  {exp.highlights.map((highlight, i) => (
                    <li key={i}>{highlight}</li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </section>
      )}
      
      {talentCard.education && talentCard.education.length > 0 && (
        <section className="education">
          <h3>Education</h3>
          {talentCard.education.map((edu, index) => (
            <div key={index} className="education-item">
              <h4>{edu.degree}</h4>
              <p>{edu.institution}, {edu.year}</p>
              {edu.details && <p>{edu.details}</p>}
            </div>
          ))}
        </section>
      )}
      
      {talentCard.internships && talentCard.internships.length > 0 && (
        <section className="internships">
          <h3>Internships</h3>
          {talentCard.internships.map((internship, index) => (
            <div key={index} className="internship-item">
              <h4>{internship.company}</h4>
              <p>{internship.details}</p>
            </div>
          ))}
        </section>
      )}
      
      {talentCard.certifications && talentCard.certifications.length > 0 && (
        <section className="certifications">
          <h3>Certifications</h3>
          <ul>
            {talentCard.certifications.map((cert, index) => (
              <li key={index}>
                {typeof cert === 'string' ? (
                  cert
                ) : (
                  cert.url ? (
                    <a href={cert.url} target="_blank" rel="noopener noreferrer">
                      {cert.name}
                    </a>
                  ) : (
                    cert.name
                  )
                )}
              </li>
            ))}
          </ul>
        </section>
      )}
      
      {talentCard.languages && talentCard.languages.length > 0 && (
        <section className="languages">
          <h3>Languages</h3>
          <ul>
            {talentCard.languages.map((lang, index) => (
              <li key={index}>{lang}</li>
            ))}
          </ul>
        </section>
      )}

        <section className="timestamps">
          <p className="created-at">Created: {formatDate(talentCard.createdAt)}</p>
          <p className="updated-at">Last Updated: {formatDate(talentCard.updatedAt)}</p>
        </section>
    </div>
  );
}

export default TalentCardView;