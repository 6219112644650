import React, { useState, useEffect, useCallback, useRef } from 'react';
import { 
  getFirestore, 
  collection,
  doc, 
  setDoc, 
  getDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  onSnapshot, 
  serverTimestamp,
  query,
  orderBy,
  where
} from 'firebase/firestore';
import { signOut } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { PlusCircle, Send, Menu, Volume2, VolumeX, LogOut, Eye } from 'lucide-react';
import { defaultMetadata } from './metadata.js';
import { auth, db, functions } from './firebaseConfig';
import { Link } from 'react-router-dom';

// Add the RetryIndicator component
const RetryIndicator = ({ retryCount }) => (
  <div className="retry-indicator">
    Retrying... Attempt {retryCount} of 3
  </div>
);

function SessionOptionsMenu({ onRename, onDelete }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="session-options">
      <button onClick={() => setIsOpen(!isOpen)} className="options-toggle">⋮</button>
      {isOpen && (
        <div className="options-menu">
          <button onClick={() => { onRename(); setIsOpen(false); }} className="option-button">Rename</button>
          <button onClick={() => { onDelete(); setIsOpen(false); }} className="option-button">Delete</button>
        </div>
      )}
    </div>
  );
}

function Chat({ user }) {
  const [prompt, setPrompt] = useState('');
  const [displayedConversation, setDisplayedConversation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [topic] = useState('General');
  const [metadata] = useState(localStorage.getItem('chatMetadata') || defaultMetadata);
  const [sessionId, setSessionId] = useState(null);
  const [sessionName, setSessionName] = useState('');
  const [error, setError] = useState(null);
  const [sessions, setSessions] = useState({
    today: [],
    yesterday: [],
    previous7Days: [],
    previous30Days: [],
    older: []
  });
  const [audioUrl, setAudioUrl] = useState(null);
  const [isMuted, setIsMuted] = useState(false);
  const [sessionCounter, setSessionCounter] = useState(1);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768);
  const [editingSessionId, setEditingSessionId] = useState(null);
  const [editingSessionName, setEditingSessionName] = useState('');
  const audioRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [showAudioPrompt, setShowAudioPrompt] = useState(false);
  const [isInitializingSession, setIsInitializingSession] = useState(false);
  const [talentCard, setTalentCard] = useState(null);
  const [talentCardUrl, setTalentCardUrl] = useState(null);
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  const [retryCount, setRetryCount] = useState(0);
  const handleSignOut = async () => {
    try {
      await signOut(auth);
      // Redirect to login page or update app state
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const fetchSessions = useCallback(async () => {
    try {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);
      const sevenDaysAgo = new Date(today);
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
      const thirtyDaysAgo = new Date(today);
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
  
      const q = query(
        collection(db, 'conversations'), 
        where("userId", "==", user.uid),
        orderBy('lastUpdated', 'desc')
      );
  
      const querySnapshot = await getDocs(q);
      const fetchedSessions = querySnapshot.docs.map(doc => ({
        id: doc.id,
        name: doc.data().name || 'Untitled Session',
        lastUpdated: doc.data().lastUpdated?.toDate() || new Date()
      }));
  
      const groupedSessions = {
        today: [],
        yesterday: [],
        previous7Days: [],
        previous30Days: [],
        older: []
      };
  
      fetchedSessions.forEach(session => {
        if (session.lastUpdated >= today) {
          groupedSessions.today.push(session);
        } else if (session.lastUpdated >= yesterday) {
          groupedSessions.yesterday.push(session);
        } else if (session.lastUpdated >= sevenDaysAgo) {
          groupedSessions.previous7Days.push(session);
        } else if (session.lastUpdated >= thirtyDaysAgo) {
          groupedSessions.previous30Days.push(session);
        } else {
          groupedSessions.older.push(session);
        }
      });
  
      setSessions(groupedSessions);
    } catch (error) {
      console.error('Error fetching sessions:', error);
      setError('An error occurred while fetching sessions. Please try again later.');
    }
  }, [user.uid]);

  useEffect(() => {
    fetchSessions();
  }, [fetchSessions]);

  useEffect(() => {
    if (sessionId && user && user.uid) {
      const unsubscribe = onSnapshot(
        doc(db, 'conversations', sessionId),
        async (docSnapshot) => {
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            if (data.userId === user.uid) {
              const messages = data.messages || [];
              const visibleMessages = messages.filter(msg => !msg.isHidden);
              setDisplayedConversation(visibleMessages);
              setSessionName(data.name || 'Untitled Session');
              setError(null);
  
              if (data.verifiedTalentCardId) {
                const talentCardDoc = await getDoc(doc(db, 'talentCards', data.verifiedTalentCardId));
                if (talentCardDoc.exists()) {
                  const talentCardData = talentCardDoc.data();
                  setTalentCard(talentCardData);
                  setTalentCardUrl(talentCardData.url);
                } else {
                  setTalentCard(null);
                  setTalentCardUrl(null);
                }
              } else {
                setTalentCard(null);
                setTalentCardUrl(null);
              }
            } else {
              setError("You don't have permission to access this conversation.");
            }
          } else {
            setError(null);
            setSessionId(null);
            setSessionName('');
            setDisplayedConversation([]);
            setTalentCard(null);
            setTalentCardUrl(null);
            localStorage.removeItem('sessionId');
          }
        },
        (error) => {
          console.error("Error in Firestore snapshot listener:", error);
          setError('Error listening to conversation updates: ' + error.message);
        }
      );
      return () => unsubscribe();
    }
  }, [sessionId, user]);

  const loadSessionCounter = useCallback(async () => {
    const counterDoc = await getDoc(doc(db, 'metadata', 'sessionCounter'));
    if (counterDoc.exists()) {
      setSessionCounter(counterDoc.data().count);
    } else {
      await setDoc(doc(db, 'metadata', 'sessionCounter'), { count: 1 });
    }
  }, []);

  const streamAudio = useCallback((audioUrl) => {
    if (audioRef.current) {
      audioRef.current.src = audioUrl;
      audioRef.current.muted = isMuted;
      audioRef.current.play().catch(e => console.error("Error playing audio:", e));
    }
  }, [isMuted]);

  useEffect(() => {
    if (displayedConversation.length > 0) {
      const lastMessage = displayedConversation[displayedConversation.length - 1];
      if (lastMessage.role === 'model' && lastMessage.audioUrl) {
        streamAudio(lastMessage.audioUrl);
      }
    }
  }, [displayedConversation, streamAudio]);

  useEffect(() => {
    const initSession = async () => {
      if (isInitialized) return;
      
      await loadSessionCounter();
      await fetchSessions();
      setIsInitialized(true);
    };

    initSession();
  }, [loadSessionCounter, fetchSessions, isInitialized]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [displayedConversation]);

  const callGeminiFunction = async (prompt, topic, sessionId, isInitializing, isMuted) => {
    setRetryCount(0);
    const response = await fetch('https://callgemini-770592635086.europe-west2.run.app/callGemini', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${await user.getIdToken()}`
      },
      body: JSON.stringify({ 
        prompt, 
        topic,
        sessionId,
        isInitializing,
        isMuted
      })
    });

    if (response.status === 500) {
      setRetryCount(prev => prev + 1);
    }

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (prompt.trim() === '' || !sessionId) return;
    
    setIsLoading(true);
    setError(null);
    setRetryCount(0);

    try {
      const result = await callGeminiFunction(prompt, topic, sessionId, false, isMuted);

      if (result.error) {
        throw new Error(result.error);
      }

      setPrompt('');
      setAudioUrl(result.audioUrl);
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      let errorMessage = 'An unexpected error occurred. Please try again later.';
      if (error.message.includes('500 Internal Server Error')) {
        errorMessage = 'The AI service is currently experiencing issues. Please try again in a few minutes.';
      }
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSessionClick = useCallback(async (clickedSessionId) => {
    if (clickedSessionId === sessionId) return;
 
    setSessionId(clickedSessionId);
    localStorage.setItem('sessionId', clickedSessionId);
    
    const docRef = doc(db, 'conversations', clickedSessionId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      if (data.userId === user.uid) {
        setDisplayedConversation(data.messages.filter(msg => !msg.isHidden));
        setSessionName(data.name || 'Untitled Session');
      } else {
        setError("You don't have permission to access this conversation.");
        setDisplayedConversation([]);
        setSessionName('');
      }
    }
  }, [sessionId, user.uid]);

  const handleNewSession = async () => {
    const newSessionId = `session_${Date.now()}`;
    setIsLoading(true);
    setError(null);
    setShowAudioPrompt(true);
    setIsInitializingSession(true);
    setSessionId(newSessionId);
    
    try {
      const docRef = doc(db, 'conversations', newSessionId);
      await setDoc(docRef, {
        topic: topic,
        messages: [{ role: 'user', parts: metadata, isHidden: true }],
        lastUpdated: serverTimestamp(),
        name: `Untitled chat-${sessionCounter.toString().padStart(2, '0')}`,
        userId: user.uid
      });
 
      const result = await callGeminiFunction('', topic, newSessionId, true, isMuted);
  
      if (result.error) {
        throw new Error(result.error);
      }
  
      localStorage.setItem('sessionId', newSessionId);
      setSessionName(`Untitled chat-${sessionCounter.toString().padStart(2, '0')}`);
      setSessionCounter(prev => prev + 1);
      setAudioUrl(result.audioUrl);
      await fetchSessions();

    } catch (error) {
      console.error('Error in handleNewSession:', error);
      setError('Error initializing new session: ' + error.message);
    } finally {
      setIsLoading(false);
      setIsInitializingSession(false);
    }
  };

  useEffect(() => {
    if (displayedConversation.length > 0) {
      setShowAudioPrompt(false);
    }
  }, [displayedConversation]);

  const handleDeleteSession = useCallback(async (sessionIdToDelete) => {
    if (window.confirm('Are you sure you want to delete this session?')) {
      try {
        await deleteDoc(doc(db, 'conversations', sessionIdToDelete));
        if (sessionIdToDelete === sessionId) {
          setSessionId(null);
          setSessionName('');
          setDisplayedConversation([]);
          localStorage.removeItem('sessionId');
          setError(null);
        }
        await fetchSessions();
      } catch (error) {
        console.error('Error deleting session:', error);
        setError('Error deleting session: ' + error.message);
      }
    }
  }, [sessionId, fetchSessions]);

  const handleRenameSession = useCallback(async (sessionId, newName) => {
    if (newName && newName.trim() !== '') {
      await updateDoc(doc(db, 'conversations', sessionId), {
        name: newName.trim(),
        lastUpdated: serverTimestamp()
      });
      if (sessionId === sessionId) {
        setSessionName(newName.trim());
      }
      fetchSessions();
      setEditingSessionId(null);
    }
  }, [sessionId, fetchSessions]);

  const toggleMute = useCallback(() => {
    setIsMuted(prevMuted => {
      const newMuted = !prevMuted;
      if (audioRef.current) {
        audioRef.current.muted = newMuted;
      }
      return newMuted;
    });
  }, []);

  const renderSessionGroup = (title, sessions) => {
    if (sessions.length === 0) return null;
    return (
      <div key={title}>
        <h3>{title}</h3>
        {sessions.map((session) => (
          <div 
            key={session.id} 
            className={`session-item ${session.id === sessionId ? 'active' : ''}`}
          >
            {editingSessionId === session.id ? (
              <input
                className="inline-edit"
                value={editingSessionName}
                onChange={(e) => setEditingSessionName(e.target.value)}
                onBlur={() => handleRenameSession(session.id, editingSessionName)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleRenameSession(session.id, editingSessionName);
                  }
                }}
                autoFocus
              />
            ) : (
              <div 
                className="session-name"
                onClick={() => handleSessionClick(session.id)}
              >
                {session.name}
              </div>
            )}
            <SessionOptionsMenu 
              onRename={() => {
                setEditingSessionId(session.id);
                setEditingSessionName(session.name);
              }}
              onDelete={() => handleDeleteSession(session.id)}
            />
          </div>
        ))}
        <hr />
      </div>
    );
  };

  const initialTips = [
    { icon: "✨", text: "Quick Tips", type: "subheader" },
    { icon: "💡", text: "Keep it conversational", type: "tip" },
    { icon: "🤝", text: "Be honest and open", type: "tip" },
    { icon: "🎭", text: "This isn't a job interview—just a friendly chat!", type: "tip" },
  ];

  const renderLogo = () => (
    <div className="logo-container">
      <svg viewBox="0 0 100 100" width="50" height="50">
        <path d="M50 0 L100 25 L100 75 L50 100 L0 75 L0 25 Z" fill="none" stroke="currentColor" strokeWidth="5" />
        <path d="M50 25 L75 37.5 L75 62.5 L50 75 L25 62.5 L25 37.5 Z" fill="none" stroke="currentColor" strokeWidth="5" />
      </svg>
    </div>
  );

  const renderSuggestions = () => (
    <div className="suggestions-container">
      {renderLogo()}
      <div className="suggestions-grid">
        {initialTips.map((tip, index) => (
          <div key={index} className={`suggestion-item ${tip.type}`}>
            <span className="suggestion-icon">{tip.icon}</span>
            <p>{tip.text}</p>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="chat-container">
      <button className="menu-button" onClick={toggleSidebar}>
        <Menu size={24} />
      </button>
      
      <div className={`sidebar ${isSidebarOpen ? 'sidebar-visible' : 'sidebar-hidden'}`}>
        <div className="sidebar-content">
          <h2>Sessions</h2>
          <button onClick={handleNewSession} disabled={isInitializingSession} className="new-chat-button">
            <PlusCircle size={18} style={{ marginRight: '5px' }} />
            New chat
          </button>

          {talentCardUrl && (
            <Link to={`/talent-card/${talentCardUrl.split('/talent-card/')[1]}`} className="view-talent-card-button">
              <Eye size={18} style={{ marginRight: '5px' }} />
              View Talent Card
            </Link>
          )}

          {renderSessionGroup('Today', sessions.today)}
          {renderSessionGroup('Yesterday', sessions.yesterday)}
          {renderSessionGroup('Previous 7 Days', sessions.previous7Days)}
          {renderSessionGroup('Previous 30 Days', sessions.previous30Days)}
          {renderSessionGroup('Older', sessions.older)}
        </div>
        <div className="sidebar-footer">
          <div className="user-info">
            <span>{user.email}</span>
          </div>
          <button onClick={handleSignOut} className="logout-button">
            <LogOut size={18} style={{ marginRight: '5px' }} />
            Log Out
          </button>
        </div>
      </div>
  
      <div className="main-content">
        <div className="main-header">
          <button className="menu-button" onClick={toggleSidebar}>
            <Menu size={24} />
          </button>
          <h1>Pod</h1>
          <button onClick={toggleMute} className="mute-button">
            {isMuted ? <VolumeX size={24} /> : <Volume2 size={24} />}
          </button>
        </div>
        
        {error && <div className="error-message">{error}</div>}
        {isLoading && retryCount > 0 && <RetryIndicator retryCount={retryCount} />}

        <div className="chat-messages" ref={chatContainerRef}>
          {!sessionId ? (
            renderSuggestions()
          ) : (
            <>
              {showAudioPrompt && (
                <div className="audio-prompt">
                  Please put on your headphones or ensure your device's sound is on. 
                  Nia will start speaking to you in a moment.
                </div>
              )}
              {displayedConversation.map((message, index) => (
                <div 
                  key={index} 
                  className={`message-container ${message.role === 'user' ? 'user-message' : 'model-message'}`}
                >
                  <div className="message">
                    <strong>{message.role === 'user' ? 'You: ' : 'Nia: '}</strong>
                    {message.parts}
                    {message.role === 'model' && index === displayedConversation.length - 1 && (
                      <button 
                        onClick={toggleMute}
                        className="message-mute-button"
                        aria-label={isMuted ? "Unmute" : "Mute"}
                      >
                        {isMuted ? <VolumeX size={16} /> : <Volume2 size={16} />}
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </>
          )}
        </div>

        <audio ref={audioRef} style={{ display: 'none' }} />
        
        <div className="chat-input-container">
          {!sessionId ? (
            <button onClick={handleNewSession} className="new-chat-button">
              <PlusCircle size={18} style={{ marginRight: '5px' }} />
              New chat
            </button>
          ) : (
            <form onSubmit={handleSubmit} className="chat-form">
              <textarea
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                placeholder="Send a message"
                className="prompt-area"
              />
              <button 
                type="submit" 
                disabled={isLoading}
                className="send-button"
              >
                <Send size={24} />
              </button>
            </form>
          )}
          <p className="disclaimer">Pod is still under development and may make mistakes.</p>
          <p className="disclaimer">Your chat history remains <span class="highlight">private</span>.</p>
        </div>
      </div>
    </div>
  );
}

export default Chat;